import './src/styles/global.css'

export const onClientEntry = () => {
    if (typeof window !== 'undefined') {
        const userLanguages = navigator.languages || [navigator.language || 'en'];
        const hasKorean = userLanguages.some(lang => lang.startsWith('ko'));
        const currentPath = window.location.pathname;

        if (hasKorean) {
            return;
        }

        if (!currentPath.startsWith('/en/')) {
            window.location.replace(`/en${currentPath}`);
        }
    }
};